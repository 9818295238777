<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>关注回复</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <el-form ref="info" size="medium" :label-width="this.env.label_width">
                <el-row>
                    <el-col :span="16">
                        <el-form-item label="类型">
                            <el-radio-group v-model="info.type">
                                <el-radio :label="1">文本</el-radio>
                                <el-radio :label="2">图文</el-radio>
                                <el-radio :label="3">图片</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-show="info.type===1" label="回复内容">
                            <el-input v-model="info.content" type="textarea" maxlength="1000" show-word-limit
                                      rows="3"></el-input>
                        </el-form-item>
                        <el-form-item v-show="info.type===2" label="选择图文">
                            <el-select
                                    v-model="info.wxcontent_s"
                                    multiple
                                    filterable
                                    placeholder="请选择图文"
                                    remote
                                    :remote-method="getwxcontent"
                                    style="width: 100%">
                                <el-option
                                        v-for="(item,index) in wxcontent_list"
                                        :key="index"
                                        :label="item.title"
                                        :value="item.wxcontent_uuid">
                                </el-option>
                            </el-select>
                            <span style="display: block; color: #fc142f; font-size: 12px; line-height: 20px;">当用户发送文本、图片、语音、视频、图文、地理位置这六种消息时，只能回复1条图文消息；其余场景最多可回复8条图文消息</span>
                        </el-form-item>
                        <el-form-item v-show="info.type===3" label="素材id">
                            <el-input v-model="info.media_id"></el-input>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-radio-group v-model="info.state">
                                <el-radio :label="1">启用</el-radio>
                                <el-radio :label="2">禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item>
                            <el-row>
                                <el-col :span="12">
                                    <el-button v-if="is_auth('wxmp.keyword.subscribereplysave')" size="medium"
                                               type="primary" @click="issave">保存
                                    </el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

        </div>
    </div>
</template>
<script>
export default {
    components: {},
    data() {
        return {
            loading: true,      // 加载状态
            page_name: '关注回复',
            info: {
                type: 1,
                state: 1,
            },           // 详情
            wxcontent_list: [],
        }
    },
    // 创建
    created() {
        this.getinfo();
        this.getwxcontent(null);        // 获取可用图文
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 获取详情
        getinfo() {
            let postdata = {
                api_name: "wxmp.keyword.subscribereply",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.info = json.info
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 保存
        issave() {
            let postdata = {
                api_name: 'wxmp.keyword.subscribereplysave',
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.info);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.editPage = false
                    this.$message({
                        message: '保存成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getinfo()
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 获取图文
        getwxcontent(title) {
            if (title === '') return false;
            let postdata = {
                api_name: "wxmp.wxcontent.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: 20,
                title,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.wxcontent_list = json.data.list
                } else {
                    this.wxcontent_list = []
                }
            })

        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box {
    border: 1px solid #40a9ff;
}

.box-content {
    display: flex;
    padding: 10px;
}

.box-content-border {
    padding: 10px;
}

.content-title {
    width: 20%;
    padding-right: 20px;
    text-align: center;
}
</style>
